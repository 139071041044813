import React, { useEffect, useState } from "react";
import api from "../../axios/api";
import { FaFileAlt, FaUser, FaCheckCircle } from "react-icons/fa";
import ReactLoading from "react-loading";
import "../../assets/NewFiles/Css/searchcomponent.css";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    treeName: false,
    uploadedBy: false,
    location: false,
  });
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/upload/all-images`);

        setData(response.data.images);
        setStats(response.data.stats);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const filteredData = data.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      (!filters.treeName ||
        item.treeName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.uploadedBy ||
        item.userId.userName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.location ||
        item.address.toLowerCase().includes(lowerSearchTerm))
    );
  });

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="search-component">
      <div className="search-container">
        <div className="search-row">
          <div className="search-col">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              className="search-input"
            />
          </div>
          <div className="search-col">
            <div className="filters">
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    name="treeName"
                    checked={filters.treeName}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Tree Name
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="uploadedBy"
                    checked={filters.uploadedBy}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Uploaded By
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="location"
                    checked={filters.location}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Location
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stats-container">
        <div className="stat-item">
          <FaFileAlt className="stat-icon" />
          <span className="stat-text">
            Total Plants:{" "}
            <span className="stat-value">{stats[0].totalTrees || 0}</span>
          </span>
        </div>
        <div className="stat-item">
          <FaCheckCircle className="stat-icon" />
          <span className="stat-text">
            Verified Plants:{" "}
            <span className="stat-value">{stats[0].verifiedTrees || 0}</span>
          </span>
        </div>
        <div className="stat-item">
          <FaUser className="stat-icon" />
          <span className="stat-text">
            Total Users:{" "}
            <span className="stat-value">{stats[0].totalUsers || 0}</span>
          </span>
        </div>
        <div className="stat-item">
          <FaCheckCircle className="stat-icon" />
          <span className="stat-text">
            Verified Users:{" "}
            <span className="stat-value">{stats[0].verifiedUsers || 0}</span>
          </span>
        </div>
      </div>

      <section className="card-section">
        <div className="card-container">
          <div className="card-row">
            {filteredData.map((item, index) => (
              <div key={index} className="card-col">
                <div className="blogCardItem">
                  <div className="blogCardImage">
                    <img
                      src={item.treeImageUrl}
                      alt={item.treeName}
                      className="card-image"
                    />
                  </div>

                  <span className="card-plantName">
                    <span className="verified">
                      {item.isVerified ? <FaCheckCircle color="green" /> : ""}
                    </span>
                    &nbsp;&nbsp;{item.treeName}
                  </span>

                  <div className="blogCardDetails">
                    <span className="plantedBy">
                      Planted by: {item.userId.userName}
                    </span>
                    <span className="card-detail">
                      Uploaded On: {new Date(item.createdAt).toDateString()}
                    </span>
                    <span className="card-detail">
                      Planted On: {new Date(item.plantedOn).toDateString()}
                    </span>
                    <span className="card-detail">
                      Location: {item.address}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchComponent;

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};
