import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import "../../assets/NewFiles/Css/searchcomponent.css";

const ProfilePosts = ({ initialData = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    treeName: false,
    uploadedBy: false,
    location: false,
  });
  const data = initialData;

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const filteredData = data.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      (!filters.treeName || item.treeName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.location || item.address.toLowerCase().includes(lowerSearchTerm))
    );
  });

  return (
    <div className="search-component">
      <div className="filters">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
          className="search-input"
        />
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="treeName"
              checked={filters.treeName}
              onChange={handleCheckboxChange}
              className="checkbox-input"
            />
            Tree Name
          </label>
          <label>
            <input
              type="checkbox"
              name="location"
              checked={filters.location}
              onChange={handleCheckboxChange}
              className="checkbox-input"
            />
            Location
          </label>
        </div>
      </div>
      <section className="card-section">
        <div className="card-container">
          <div className="card-row">
            {filteredData.map((item, index) => (
              <div key={index} className="card-col">
                <div className="blogCardItem">
                  <div className="blogCardImage">
                    <img
                      src={item.treeImageUrl}
                      alt={item.treeName}
                      className="card-image"
                    />
                  </div>

                  <span className="card-plantName">
                    <span className="verified">
                      {item.isVerified ? <FaCheckCircle color="green" /> : ""}
                    </span>
                    &nbsp;&nbsp;{item.treeName}
                  </span>

                  <div className="blogCardDetails">
                    <span className="plantedBy">
                      Planted by: {item.userId.userName}
                    </span>
                    <span className="card-detail">
                      Uploaded On: {new Date(item.createdAt).toDateString()}
                    </span>
                    <span className="card-detail">
                      Planted On: {new Date(item.plantedOn).toDateString()}
                    </span>
                    <span className="card-detail">
                      Location: {item.address}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

ProfilePosts.propTypes = {
  initialData: PropTypes.array,
};

export default ProfilePosts;
